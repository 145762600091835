import React from "react"
import * as FaIcons from "react-icons/fa"
import { Link } from "gatsby"

import Seo from "../components/seo"

// excerpt, id, date, slug,

const article = ({ pageContext: { title, content, featuredImage } }) => {
  return (
    <section id="article">
      <Seo title={title} />
      {/* <Link className="back" to="/news/">
        <FaIcons.FaArrowLeft id="back-arrow" />
        <span className="back-text">Back to News Page</span>
      </Link> */}
      <div className="img-container">
        <img src={featuredImage?.node?.mediaItemUrl} alt="" />
        <div className="overlay">
          <h2>{title}</h2>
        </div>
      </div>
      <div className="container">
        <div className="content-wrap">
          {/* <span className="date">{date}</span> */}
          <div
            className="content"
            dangerouslySetInnerHTML={{ __html: content }}
          />
        </div>
      </div>
    </section>
  )
}

export default article
